import { EIQFile } from '@edgeiq/edgeiq-api-js';
import { Box, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import clsx from 'clsx';

import useStyles from '../../components/Card/styles';

interface FileCardProps {
  file: EIQFile;
  handleDownloadFile: (file: EIQFile) => () => void;
}

const FileCard: React.FC<FileCardProps> = ({ file, handleDownloadFile }) => {
  const { name, mime_type, company_id } = file;
  const cardClasses = useStyles();
  return (
    <Box>
      <Grid container spacing={2} alignItems={'center'}>
        <Grid item xs={12} md={10}>
          <Tooltip placement="top" title={name}>
            <Typography
              variant="button"
              component="div"
              noWrap
              className={clsx('mb-1', cardClasses.title)}
            >
              {name}
            </Typography>
          </Tooltip>
        </Grid>

        <Grid item xs={12} md={2}>
          <Tooltip placement="top" title="Download file">
            <IconButton
              aria-label="download-file"
              onClick={handleDownloadFile(file)}
              size="small"
            >
              <FileDownloadIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>

      <Typography
        variant="button"
        component="div"
        className={clsx('mb-4', cardClasses.subtitle)}
      >
        CompanyId: {company_id}
      </Typography>
      <Typography
        variant="button"
        component="div"
        className={clsx('mb-4', cardClasses.subtitle)}
      >
        MIME type: {mime_type}
      </Typography>
    </Box>
  );
};

export default FileCard;
