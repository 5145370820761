import { Company } from '@edgeiq/edgeiq-api-js';
import { defaultFilters } from '../../helpers/defaultFilters';
import { getFilters, setLocalFilters } from '../../helpers/storage';
import { ExplorerLevel, FilterObject } from '../../models/common';
import { UserTypes } from './user.reducer';

type HierarchyIndexes = Record<
  string,
  {
    index: number;
    levelIndex: number;
  }
>;

type HierarchyFilters = {
  levels: ExplorerLevel[];
  searchAccount: Company | undefined;
  selectedAccountsIndexes: HierarchyIndexes;
  ancestorAccountIndexes: HierarchyIndexes;
};

type FilterSubData = { [key: string]: string | boolean | string[] };

type FilterData = {
  data: FilterSubData | string | boolean;
  filterName: FiltersKeyName;
};

const FiltersTypes = {
  SET_CASE_SENSITIVE: 'SET_CASE_SENSITIVE',
  SET_EXPORT_DEVICES_COLUMNS: 'SET_EXPORT_DEVICES_COLUMNS',
  SET_FILTERS: 'SET_FILTERS',
  SET_HIERARCHY_FILTERS: 'SET_HIERARCHY_FILTERS',
  SET_HIERARCHY_LEVELS: 'SET_HIERARCHY_LEVELS',
  SET_HIERARCHY_SEARCH_ACCOUNT: 'SET_HIERARCHY_SEARCH_ACCOUNT',
  SET_MORE_FILTERS_VIEW: 'SET_MORE_FILTERS_VIEW',
  SET_SORTING: 'SET_SORTING',
  SET_VIEW_OPTION: 'SET_VIEW_OPTION',
};

export const setSorting = (
  sorting: string,
  filterName: FiltersKeyName,
): FiltersAction => ({
  data: {
    data: sorting,
    filterName,
  },
  type: FiltersTypes.SET_SORTING,
});

export const setViewOption = (
  viewOption: string,
  filterName: FiltersKeyName,
): FiltersAction => ({
  data: {
    data: viewOption,
    filterName,
  },
  type: FiltersTypes.SET_VIEW_OPTION,
});

export const setCaseSensitive = (
  caseSensitive: boolean,
  filterName: FiltersKeyName,
): FiltersAction => ({
  data: {
    data: caseSensitive,
    filterName,
  },
  type: FiltersTypes.SET_CASE_SENSITIVE,
});

export const setFilters = (
  filters: FilterSubData,
  filterName: FiltersKeyName,
): FiltersAction => ({
  data: {
    data: filters,
    filterName,
  },
  type: FiltersTypes.SET_FILTERS,
});

export const setHierarchyFilters = (
  hierarchyFilters: HierarchyFilters,
): FiltersAction => ({
  data: hierarchyFilters,
  type: FiltersTypes.SET_HIERARCHY_FILTERS,
});

export const setHierarchyLevels = (levels: ExplorerLevel[]): FiltersAction => ({
  data: levels,
  type: FiltersTypes.SET_HIERARCHY_LEVELS,
});

export const setHierarchySearchAccount = (
  account: Company | undefined,
): FiltersAction => ({
  data: account,
  type: FiltersTypes.SET_HIERARCHY_SEARCH_ACCOUNT,
});

export const setMoreFiltersView = (
  view: string,
  filterName: FiltersKeyName,
): FiltersAction => ({
  data: {
    data: view,
    filterName,
  },
  type: FiltersTypes.SET_MORE_FILTERS_VIEW,
});

export const setExportDevicesData = (
  data: FilterSubData,
  filterName: FiltersKeyName,
): FiltersAction => ({
  data: {
    data,
    filterName,
  },
  type: FiltersTypes.SET_EXPORT_DEVICES_COLUMNS,
});

export type FiltersState = {
  actions: FilterObject;
  alerts: FilterObject;
  bulkJobs: FilterObject;
  case_sensitive: boolean;
  configs: FilterObject;
  commands: FilterObject;
  commandsExecutions: FilterObject;
  companies: FilterObject;
  configurations: FilterObject;
  devices: FilterObject;
  deviceErrors: FilterObject;
  devicesTypes: FilterObject;
  deviceTransfers: FilterObject;
  discoveredDevices: FilterObject;
  escrowDevices: FilterObject;
  files: FilterObject;
  gatewayCommands: FilterObject;
  heartbeats: FilterObject;
  hierarchyFilters: HierarchyFilters;
  ingestors: FilterObject;
  integrations: FilterObject;
  integrationEvents: FilterObject;
  messageReports: FilterObject;
  policies: FilterObject;
  pollableAttributes: FilterObject;
  scheduledJobs: FilterObject;
  secrets: FilterObject;
  settingsApplicationRecord: FilterObject;
  softwareUpdates: FilterObject;
  translators: FilterObject;
  users: FilterObject;
  userTypes: FilterObject;
  workflows: FilterObject;
};

// INITIAL STATE
export const filterInitialState: FiltersState = {
  ...defaultFilters,
  ...getFilters(),
};

export type FiltersKeyName = keyof typeof filterInitialState;

export type FiltersAction = {
  data: FilterData | HierarchyFilters | ExplorerLevel[] | Company | undefined;
  type: string;
};

const filtersReducer = (
  state = filterInitialState,
  action: FiltersAction,
): FiltersState => {
  let newState = null;
  // Each dispatch will run all reducers, to avoid using setLocalFilters at each dipatch,
  // we only set it to be run when the action is related to filters and we want to store the result in localStorage
  let saveFilters = false;
  switch (action.type) {
    case FiltersTypes.SET_SORTING:
      saveFilters = true;
      const sortigData = action.data as FilterData;
      newState = {
        ...state,
        [sortigData.filterName]: {
          ...(state[sortigData.filterName] as FilterObject),
          sortBy: sortigData.data,
        },
      };
      break;

    case FiltersTypes.SET_VIEW_OPTION:
      saveFilters = true;
      const viewData = action.data as FilterData;
      newState = {
        ...state,
        [viewData.filterName]: {
          ...(state[viewData.filterName] as FilterObject),
          view: viewData.data,
        },
      };
      break;

    case FiltersTypes.SET_CASE_SENSITIVE:
      saveFilters = true;
      const caseSensitiveData = action.data as FilterData;
      console.info(caseSensitiveData);
      newState = {
        ...state,
        case_sensitive: caseSensitiveData.data as boolean,
      };
      break;

    case FiltersTypes.SET_FILTERS:
      saveFilters = true;
      const filterData = action.data as FilterData;
      newState = {
        ...state,
        [filterData.filterName]: {
          ...(state[filterData.filterName] as FilterObject),
          filters: filterData.data,
        },
      };
      break;

    case FiltersTypes.SET_HIERARCHY_FILTERS:
      const filters = action.data as HierarchyFilters;
      newState = {
        ...state,
        hierarchyFilters: filters,
      };
      break;

    case FiltersTypes.SET_HIERARCHY_LEVELS:
      const levels = action.data as ExplorerLevel[];
      newState = {
        ...state,
        hierarchyFilters: {
          ...state.hierarchyFilters,
          levels,
        },
      };
      break;

    case FiltersTypes.SET_HIERARCHY_SEARCH_ACCOUNT:
      const searchAccount = action.data as Company | undefined;
      newState = {
        ...state,
        hierarchyFilters: {
          ...state.hierarchyFilters,
          searchAccount,
        },
      };
      break;

    case FiltersTypes.SET_MORE_FILTERS_VIEW:
      saveFilters = true;
      const moreFiltersView = action.data as FilterData;
      newState = {
        ...state,
        [moreFiltersView.filterName]: {
          ...(state[moreFiltersView.filterName] as FilterObject),
          moreFiltersView: moreFiltersView.data,
        },
      };
      break;

    case FiltersTypes.SET_EXPORT_DEVICES_COLUMNS:
      saveFilters = true;
      const exportData = action.data as FilterData;
      newState = {
        ...state,
        [exportData.filterName]: {
          ...(state[exportData.filterName] as FilterObject),
          defaultColumns: (exportData.data as FilterSubData).defaultColumns,
          exportColumns: (exportData.data as FilterSubData).exportColumns,
        },
      };
      break;

    case UserTypes.LOGOUT_USER:
      newState = {
        ...defaultFilters,
      };
      break;

    default:
      newState = state;
      break;
  }
  if (saveFilters) {
    // To set the hierarchy filters in the local storage is probably going to create a lot of issues
    // It makes more sense that if the user reloads the page, they lose the last hierarchy tree search.
    setLocalFilters({
      ...newState,
      hierarchyFilters: defaultFilters.hierarchyFilters,
    });
  }
  return newState;
};

export default filtersReducer;
