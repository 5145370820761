import { CombinedState, combineReducers } from 'redux';

import alertReducer, {
  AlertAction,
  AlertState,
} from './reducers/alert.reducer';
import commandsReducer, {
  CommandsAction,
  CommandsState,
} from './reducers/commands.reducer';
import commandsExecutionsReducer, {
  CommandsExecutionsAction,
  CommandsExecutionsState,
} from './reducers/commandsExecutions.reducer';
import companiesReducer, {
  CompaniesAction,
  CompaniesState,
} from './reducers/companies.reducer';
import configsReducer, {
  ConfigsAction,
  ConfigsState,
} from './reducers/configs.reducer';
import configurationReducer, {
  ConfigurationAction,
  ConfigurationState,
} from './reducers/configuration.reducer';
import devicesReducer, {
  DevicesAction,
  DevicesState,
} from './reducers/devices.reducer';
import deviceTransferReducer, {
  DeviceTransferRequestAction,
  DeviceTransferState,
} from './reducers/deviceTransfer.reducer';
import deviceTypesReducer, {
  DeviceTypesAction,
  DeviceTypesState,
} from './reducers/deviceTypes.reducer';
import companiesFilesReducer, {
  FilesAction,
  FilesState,
} from './reducers/files.reducer';
import filtersReducer, {
  FiltersAction,
  FiltersState,
} from './reducers/filters.reducer';
import ingestorsReducer, {
  IngestorsAction,
  IngestorsState,
} from './reducers/ingestors.reducer';
import integrationsReducer, {
  IntegrationsAction,
  IntegrationsState,
} from './reducers/integrations.reducer';
import policiesReducer, {
  PoliciesAction,
  PoliciesState,
} from './reducers/policies.reducer';
import pollableAttributesReducer, {
  PollableAttributesAction,
  PollableAttributesState,
} from './reducers/pollableAttributes.reducer';
import settingReducer, {
  SettingAction,
  SettingState,
} from './reducers/settings.reducer';
import settingsApplicationRecordReducer, {
  SettingsApplicationRecordAction,
  SettingsApplicationRecordState,
} from './reducers/settingsApplicationRecord.reducer';
import softwareUpdatesReducer, {
  SoftwareUpdatesAction,
  SoftwareUpdatesState,
} from './reducers/softwareUpdates.reducer';
import translatorsReducer, {
  TranslatorsAction,
  TranslatorsState,
} from './reducers/translators.reducer';
import userReducer, {
  UserAction,
  UserState,
  UserTypes,
} from './reducers/user.reducer';
import usersReducer, {
  UsersAction,
  UsersState,
} from './reducers/users.reducer';
import userTypesReducer, {
  UserTypesAction,
  UserTypesState,
} from './reducers/userTypes.reducer';

import heartbeatsReducer, {
  HeartbeatsAction,
  HeartbeatsState,
} from './reducers/heartbeats.reducer';

import messageReportsReducer, {
  MessageReportsAction,
  MessageReportsState,
} from './reducers/reports.reducer';

import gatewayCommandsReducer, {
  GatewayCommandsAction,
  GatewayCommandState,
} from './reducers/gatewayCommands';

import deviceErrorsReducer, {
  DeviceErrorsAction,
  DeviceErrorState,
} from './reducers/deviceError';

import integrationEventsReducer, {
  IntegrationEventAction,
  IntegrationEventState,
} from './reducers/integrationEvents.reducer';
import scheduledJobReducer, {
  ScheduledJobState,
  ScheduledJobAction,
} from './reducers/scheduledJobs.reducer';

import workflowsReducer, {
  WorkflowsAction,
  WorkflowsState,
} from './reducers/workflows.reducer';

const appReducer = combineReducers({
  /* your app’s top-level reducers */
  alert: alertReducer,
  commands: commandsReducer,
  commandsExecutions: commandsExecutionsReducer,
  companies: companiesReducer,
  configs: configsReducer,
  configurations: configurationReducer,
  deviceErrors: deviceErrorsReducer,
  devices: devicesReducer,
  deviceTransfer: deviceTransferReducer,
  deviceTypes: deviceTypesReducer,
  files: companiesFilesReducer,
  filters: filtersReducer,
  gatewayCommands: gatewayCommandsReducer,
  heartbeats: heartbeatsReducer,
  ingestors: ingestorsReducer,
  integrationEvents: integrationEventsReducer,
  integrations: integrationsReducer,
  messageReports: messageReportsReducer,
  policies: policiesReducer,
  pollableAttributes: pollableAttributesReducer,
  scheduledJobs: scheduledJobReducer,
  setting: settingReducer,
  settingsApplicationRecord: settingsApplicationRecordReducer,
  softwareUpdates: softwareUpdatesReducer,
  translators: translatorsReducer,
  user: userReducer,
  users: usersReducer,
  userTypes: userTypesReducer,
  workflows: workflowsReducer,
});

const rootReducer = (
  state:
    | CombinedState<{
        alert: AlertState;
        commands: CommandsState;
        commandsExecutions: CommandsExecutionsState;
        companies: CompaniesState;
        configs: ConfigsState;
        configurations: ConfigurationState;
        files: FilesState;
        deviceErrors: DeviceErrorState;
        devices: DevicesState;
        deviceTypes: DeviceTypesState;
        deviceTransfer: DeviceTransferState;
        filters: FiltersState;
        ingestors: IngestorsState;
        integrationEvents: IntegrationEventState;
        integrations: IntegrationsState;
        policies: PoliciesState;
        pollableAttributes: PollableAttributesState;
        setting: SettingState;
        settingsApplicationRecord: SettingsApplicationRecordState;
        softwareUpdates: SoftwareUpdatesState;
        translators: TranslatorsState;
        user: UserState;
        users: UsersState;
        userTypes: UserTypesState;
        heartbeats: HeartbeatsState;
        messageReports: MessageReportsState;
        gatewayCommands: GatewayCommandState;
        scheduledJobs: ScheduledJobState;
        workflows: WorkflowsState;
      }>
    | undefined,
  action:
    | AlertAction
    | CommandsAction
    | CommandsExecutionsAction
    | CompaniesAction
    | ConfigsAction
    | ConfigurationAction
    | DevicesAction
    | DeviceTypesAction
    | DeviceTransferRequestAction
    | FilesAction
    | FiltersAction
    | IngestorsAction
    | IntegrationsAction
    | PoliciesAction
    | PollableAttributesAction
    | SoftwareUpdatesAction
    | SettingAction
    | SettingsApplicationRecordAction
    | SoftwareUpdatesAction
    | TranslatorsAction
    | UserAction
    | UsersAction
    | UserTypesAction
    | HeartbeatsAction
    | MessageReportsAction
    | GatewayCommandsAction
    | DeviceErrorsAction
    | IntegrationEventAction
    | ScheduledJobAction
    | WorkflowsAction,
): CombinedState<{
  alert: AlertState;
  commands: CommandsState;
  commandsExecutions: CommandsExecutionsState;
  companies: CompaniesState;
  configs: ConfigsState;
  configurations: ConfigurationState;
  deviceErrors: DeviceErrorState;
  devices: DevicesState;
  deviceTransfer: DeviceTransferState;
  deviceTypes: DeviceTypesState;
  files: FilesState;
  filters: FiltersState;
  ingestors: IngestorsState;
  integrationEvents: IntegrationEventState;
  integrations: IntegrationsState;
  policies: PoliciesState;
  pollableAttributes: PollableAttributesState;
  setting: SettingState;
  settingsApplicationRecord: SettingsApplicationRecordState;
  softwareUpdates: SoftwareUpdatesState;
  translators: TranslatorsState;
  user: UserState;
  users: UsersState;
  userTypes: UserTypesState;
  heartbeats: HeartbeatsState;
  messageReports: MessageReportsState;
  gatewayCommands: GatewayCommandState;
  scheduledJobs: ScheduledJobState;
  workflows: WorkflowsState;
}> => {
  if (action.type === UserTypes.LOGOUT_USER) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
