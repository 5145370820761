import { useEffect, useState, ChangeEvent } from 'react';
import { Button, CircularProgress, Grid, Typography } from '@mui/material';
import {
  Users,
  User,
  PaginationFilter,
  UserType,
  UserTypes,
} from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import { RootState } from '../../redux/store';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setAlert } from '../../redux/reducers/alert.reducer';
import {
  setSorting,
  setViewOption,
} from '../../redux/reducers/filters.reducer';
import { setStateUsers } from '../../redux/reducers/users.reducer';
import { setStateUserTypes } from '../../redux/reducers/userTypes.reducer';
import { SortingOption } from '../../models/common';
import getInitialSorting from '../../helpers/getInitialSorting';
import parseFilters from '../../helpers/parseFilters';
import {
  errorHighlight,
  defaultItemsPerPage,
  optionsPaginationsFilter,
  genericViewOptions,
  deleteHighlight,
} from '../../app/constants';
import Header from '../../containers/HeaderWithActionButton';
import CardsGrid from '../../components/CardsGrid';
import Card from '../../components/Card';
import ListSelection from '../../components/ListSelection';
import SharedTable from '../../components/SharedTable';
import ActionDialog from '../../components/ActionDialog';
import UsersFilters from './UsersFilters';
import UserCard from './UserCard';
import { ADMIN_USER_TYPE_NAME } from './constants';
import { UserColumns } from './columns';
import { SORTING_OPTIONS_USERS } from '../../constants/sortings';

const UsersPage: React.FC = () => {
  const dispatch = useAppDispatch();

  const filters = useAppSelector((state: RootState) => state.filters);
  const usersState = useAppSelector((state: RootState) => state.users);
  const userTypesState = useAppSelector((state: RootState) => state.userTypes);
  const { userCompanies } = useAppSelector((state: RootState) => state.user);

  const [users, setUsers] = useState<User[]>(usersState.users);
  const [userTypes, setUserTypes] = useState<UserType[]>(
    userTypesState.userTypes,
  );
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [selectedView, setSelectedView] = useState(filters.users.view);
  const [selectedSorting, setSelectedSorting] = useState<SortingOption>(
    getInitialSorting(filters.users.sortBy, SORTING_OPTIONS_USERS),
  );

  const [ActionDialogOpen, setActionDialogOpen] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  useEffect(() => {
    if (userTypes.length === 0) {
      UserTypes.list({}, optionsPaginationsFilter)
        .then((result) => {
          const { userTypes: resultUserTypes } = result;
          const filteredUserTyped = resultUserTypes.filter(
            (ut) => ut.name !== ADMIN_USER_TYPE_NAME,
          );
          setUserTypes(filteredUserTyped);
          dispatch(setStateUserTypes(filteredUserTyped));
        })
        .catch((error) => {
          dispatchError(error.message);
        });
    }
  }, []);

  const whenComponetMounted = (newPage: number): void => {
    setLoading(true);
    setSelectedUsers([]);
    getUsers(newPage);
  };

  useEffect(() => {
    whenComponetMounted(1);
  }, [filters.users]);

  const dispatchError = (errorMessage: string): void => {
    dispatch(
      setAlert({
        highlight: errorHighlight,
        message: errorMessage,
        type: 'error',
      }),
    );
  };

  const noLoading = (): void => {
    setLoading(false);
    setLoadingMore(false);
  };

  const getUsers = (pageNumber: number, addPage = false): void => {
    const pagination: PaginationFilter = {
      itemsPerPage: defaultItemsPerPage,
      order_by: selectedSorting.value,
      page: pageNumber,
    };

    Users.list(
      parseFilters(filters.users.filters ?? {}, filters.case_sensitive),
      pagination,
    )
      .then((result) => {
        const newUsers = addPage ? [...users, ...result.users] : result.users;
        setUsers(newUsers);
        dispatch(setStateUsers(newUsers));
        setTotalAndPage(result.pagination.total, addPage);
      })
      .catch((error) => {
        dispatchError(error.message);
      })
      .finally(() => noLoading());
  };

  const setTotalAndPage = (newTotal: number, addPage = false): void => {
    setTotal(newTotal);
    if (addPage) {
      setPage(page + 1);
    }
  };

  const handleLoadMore = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    setLoadingMore(true);
    getUsers(page + 1, true);
  };

  const checkUserCallback =
    (userId: string) =>
    (event: ChangeEvent<HTMLInputElement>): void => {
      if (event.target.checked) {
        setSelectedUsers([...selectedUsers, userId]);
      } else {
        setSelectedUsers(selectedUsers.filter((item) => item !== userId));
      }
    };

  const handleSorting = (option: SortingOption): void => {
    dispatch(setSorting(option.value, 'users'));
    setSelectedSorting(option);
  };

  const handleSelectView = (view: string): void => {
    dispatch(setViewOption(view, 'users'));
    setSelectedView(view);
  };

  const handleSelectAll = (): void => {
    if (selectedUsers.length !== users.length) {
      setSelectedUsers(users.map((userType) => userType._id));
    } else {
      setSelectedUsers([]);
    }
  };

  const handleTableSorting = (value: string): void => {
    console.info(value);
  };

  const generateCards = (): JSX.Element[] => {
    return users.map((user) => (
      <Card
        checked={selectedUsers.includes(user._id)}
        checkboxCallback={checkUserCallback}
        id={user._id}
        baseLink="/user"
        content={
          <UserCard
            user={user}
            userType={userTypes.find(
              (userType) => userType._id === user.user_type_id,
            )}
            userCompanies={userCompanies}
          />
        }
      />
    ));
  };

  const openDeleteModal = (): void => {
    setActionDialogOpen(true);
  };

  const closeDeleteModal = (): void => {
    setActionDialogOpen(false);
  };

  const handleBulkDelete = (): void => {
    setLoadingDelete(true);
    if (selectedUsers.length === 1) {
      Users.delete(selectedUsers[0])
        .then((_result) => {
          dispatch(
            setAlert({
              highlight: deleteHighlight(selectedUsers.length, 'User', 'Users'),
              type: 'success',
            }),
          );
          whenComponetMounted(page);
        })
        .catch((error) => {
          dispatchError(error.message);
        })
        .finally(() => {
          setLoadingDelete(false);
          closeDeleteModal();
        });
    } else {
      Users.deleteMultiple(selectedUsers)
        .then((_result) => {
          dispatch(
            setAlert({
              highlight: deleteHighlight(selectedUsers.length, 'User', 'Users'),
              type: 'success',
            }),
          );
          whenComponetMounted(1);
        })
        .catch((error) => {
          dispatchError(error.message);
        })
        .finally(() => {
          setLoadingDelete(false);
          closeDeleteModal();
        });
    }
  };

  return (
    <Grid container direction="column" spacing={0}>
      <Header
        title="Users"
        link="new-user"
        actionLabel="Create New User"
        model="user"
      />
      <UsersFilters userTypes={userTypes} total={total} />
      <ListSelection
        selectedSorting={selectedSorting}
        selectedView={selectedView}
        sortingOptions={SORTING_OPTIONS_USERS}
        viewsOptions={genericViewOptions}
        itemsSelected={selectedUsers.length !== 0}
        allSelected={
          users.length !== 0 && selectedUsers.length === users.length
        }
        deleteAction={true}
        sortingCallback={handleSorting}
        selectAllCallback={handleSelectAll}
        selectViewCallback={handleSelectView}
        deleteCallback={openDeleteModal}
        selectedLabel="User"
        itemsSelectedCount={selectedUsers.length}
      />
      {loading ? (
        <Grid container className="loading-container">
          <CircularProgress size={75} thickness={5} />
        </Grid>
      ) : (
        <>
          {selectedView === 'grid' && <CardsGrid cards={generateCards()} />}
          {selectedView === 'list' && (
            <SharedTable
              columns={UserColumns(userTypes, userCompanies)}
              rows={users}
              sortBy={selectedSorting.value}
              sortDirection={
                selectedSorting.value.indexOf('-') === -1 ? 'asc' : 'desc'
              }
              allSelected={selectedUsers.length === users.length}
              loading={loading}
              selectedItemsIds={selectedUsers}
              onRequestSort={handleTableSorting}
              selectAllCallback={handleSelectAll}
              checkboxCallback={checkUserCallback}
            />
          )}
          {users.length !== total && (
            <Grid item xs={12} className={clsx('mb-9 loading-container')}>
              <Button variant="outlined" size="large" onClick={handleLoadMore}>
                {!loadingMore ? (
                  <Typography variant="button">Load more</Typography>
                ) : (
                  <CircularProgress size={25} />
                )}
              </Button>
            </Grid>
          )}
        </>
      )}

      <ActionDialog
        open={ActionDialogOpen}
        loading={loadingDelete}
        content={
          <>
            <span>{`You are about to delete this ${
              selectedUsers.length === 1 ? 'user' : 'users'
            }:`}</span>
            <ul>
              {users
                .filter((user) => selectedUsers.includes(user._id))
                .map((user) => (
                  <li key={user._id}>
                    {user.first_name} - Id: {user._id}
                  </li>
                ))}
            </ul>
          </>
        }
        onCloseCallback={closeDeleteModal}
        onDeleteCallback={handleBulkDelete}
      />
    </Grid>
  );
};

export default UsersPage;
