import React, { useState } from 'react';
import { Button } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FileUploadIcon from '@mui/icons-material/FileUpload';

import { useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import { GENRE_ACTIVE, GENRE_DISCOVERED } from '../../app/constants';
import ImportDevices from './ImportDevices';
import ExportDevices from './ExportDevices';

interface DevicesHeaderActionsProps {
  onImportSuccess: () => void;
}

const DevicesHeaderActions: React.FC<DevicesHeaderActionsProps> = ({
  onImportSuccess,
}) => {
  const stateUser = useAppSelector((state: RootState) => state.user);
  const devicesState = useAppSelector((state: RootState) => state.devices);
  const isActive = devicesState.devicesGenre === GENRE_ACTIVE;
  const isDiscovered = devicesState.devicesGenre === GENRE_DISCOVERED;

  const [openImport, setOpenImport] = useState(false);
  const [openExport, setOpenExport] = useState(false);

  const handleOpenImport = (): void => {
    setOpenImport(true);
  };

  const handleCloseImport = (): void => {
    setOpenImport(false);
  };

  const handleOpenExport = (): void => {
    setOpenExport(true);
  };

  const handleCloseExport = (): void => {
    setOpenExport(false);
  };

  const handleImportSuccess = (): void => {
    handleCloseImport();
    onImportSuccess();
  };

  const canImport =
    (isActive &&
      stateUser &&
      stateUser.permissions &&
      stateUser.permissions.device &&
      stateUser.permissions.device.create) ||
    (isDiscovered &&
      stateUser &&
      stateUser.permissions &&
      stateUser.permissions.discovered_device &&
      stateUser.permissions.discovered_device.create);

  return (
    <>
      {canImport && (
        <Button
          variant="outlined"
          size="large"
          startIcon={<FileUploadIcon />}
          onClick={handleOpenImport}
        >
          {isActive ? 'Import' : 'CSV Promote'}
        </Button>
      )}
      <Button
        variant="outlined"
        size="large"
        className="ml-4"
        startIcon={<FileDownloadIcon />}
        onClick={handleOpenExport}
      >
        Export
      </Button>
      <ImportDevices
        openDrawer={openImport}
        handleCloseDrawer={handleCloseImport}
        handleImportSuccess={handleImportSuccess}
      />

      <ExportDevices
        openDrawer={openExport}
        handleCloseDrawer={handleCloseExport}
      />
    </>
  );
};

export default DevicesHeaderActions;
