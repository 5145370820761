import React, { useEffect, useState } from 'react';
import {
  Typography,
  Grid,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  IconButton,
  Checkbox,
  Autocomplete,
  TextField,
} from '@mui/material';
import {
  Delete as DeleteIcon,
  InfoOutlined as InfoIcon,
} from '@mui/icons-material';
import {
  DeviceExportColumnn,
  Devices,
  DevicesFilters,
  DiscoveredDevices,
  DiscoveredDevicesFilters,
} from '@edgeiq/edgeiq-api-js';
import { cloneDeep, difference } from 'lodash';
import clsx from 'clsx';

import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import { setExportDevicesData } from '../../redux/reducers/filters.reducer';
import { setAlert } from '../../redux/reducers/alert.reducer';
import {
  activeDevicesFileName,
  discoveredDevicesFileName,
  errorHighlight,
  GENRE_ACTIVE,
} from '../../app/constants';
import { downloadBlobFile } from '../../helpers/utils';
import parseFilters from '../../helpers/parseFilters';
import RightDrawer from '../../components/RightDrawer/RightDrawer';
import SwitchButton from '../../components/SwitchButton';
// import SelectInput from '../../components/SelectInput';
import SortableList from '../../components/SortableList';
import useStyles from './styles';

interface ExportDevicesProps {
  openDrawer: boolean;
  handleCloseDrawer: () => void;
}

interface ColumnSortableItem {
  id: number;
  column: DeviceExportColumnn;
}

const ExportDevices: React.FC<ExportDevicesProps> = ({
  openDrawer,
  handleCloseDrawer,
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const devicesState = useAppSelector((state: RootState) => state.devices);
  const filters = useAppSelector((state: RootState) => state.filters);
  const isActive = devicesState.devicesGenre === GENRE_ACTIVE;

  const [columns, setColumns] = useState<DeviceExportColumnn[]>([]);
  const [defaultColumns, setDefaultColumns] = useState(true);
  const [orderedColumns, setOrderedColumns] = useState<ColumnSortableItem[]>(
    [],
  );
  const [loading, setLoading] = useState(false);

  const dispatchError = (errorMessage: string, highlight?: string): void => {
    dispatch(
      setAlert({
        highlight: highlight ?? errorHighlight,
        message: errorMessage,
        type: 'error',
      }),
    );
  };

  const initialOrderedColumns = (result: DeviceExportColumnn[]): void => {
    if (
      (isActive && filters.devices.exportColumns?.length !== 0) ||
      (!isActive && filters.discoveredDevices.exportColumns?.length !== 0)
    ) {
      const columnsArray = isActive
        ? filters.devices.exportColumns
        : filters.discoveredDevices.exportColumns;
      if (columnsArray) {
        setOrderedColumns(
          result
            .filter((c) => columnsArray.includes(c.key))
            .map((c, i) => {
              return { column: c, id: i + 1 };
            }),
        );
      }
    }
  };

  useEffect(() => {
    let isDefault = true;
    if (isActive) {
      isDefault = filters.devices.defaultColumns ?? true;
      Devices.getExportColumns()
        .then((result) => {
          setColumns(result);
          initialOrderedColumns(result);
        })
        .catch((error) => {
          dispatchError(error.message);
        });
    } else {
      isDefault = filters.discoveredDevices.defaultColumns ?? true;
      DiscoveredDevices.getExportColumns()
        .then((result) => {
          setColumns(result);
          initialOrderedColumns(result);
        })
        .catch((error) => {
          dispatchError(error.message);
        });
    }
    setDefaultColumns(isDefault);
  }, [isActive]);

  const handleSubmitCallback = (): void => {
    setLoading(true);
    if (isActive) {
      const fitlersToApply: DevicesFilters = {
        ...parseFilters(filters.devices.filters ?? {}),
      };
      const chosenColumns = orderedColumns.map((c) => c.column.key);
      if (!defaultColumns) {
        fitlersToApply.columns = {
          operator: 'eq',
          value: chosenColumns.join(','),
        };
      }
      Devices.csvBulkDownload(fitlersToApply)
        .then((result) => {
          downloadBlobFile(result, `${activeDevicesFileName}.csv`);
          dispatch(
            setExportDevicesData(
              {
                defaultColumns: !!defaultColumns,
                exportColumns: defaultColumns ? [] : chosenColumns,
              },
              'devices',
            ),
          );
          handleCloseDrawer();
        })
        .catch((error) => {
          dispatchError(error.message);
        })
        .finally(() => setLoading(false));
    } else {
      const fitlersToApply: DiscoveredDevicesFilters = {
        ...parseFilters(
          filters.discoveredDevices.filters ?? {},
          filters.case_sensitive,
        ),
      };
      const chosenColumns = orderedColumns.map((c) => c.column.key);
      if (!defaultColumns) {
        fitlersToApply.columns = {
          operator: 'eq',
          value: chosenColumns.join(','),
        };
      }
      DiscoveredDevices.csvBulkDownload(fitlersToApply)
        .then((result) => {
          downloadBlobFile(result, `${discoveredDevicesFileName}.csv`);
          handleCloseDrawer();
        })
        .catch((error) => {
          dispatchError(error.message);
        })
        .finally(() => setLoading(false));
    }
  };

  const handleChooseDefaultColumns = (_prop: string, _value: boolean): void => {
    setDefaultColumns(!defaultColumns);
  };

  const handleChangeChosenColumns = (
    _event: React.SyntheticEvent,
    value: DeviceExportColumnn[],
  ): void => {
    // Create a string array from the ordered columns
    const exportColumns = orderedColumns.map((c) => c.column);
    // Check the columns to add and those to remove
    const toAdd = difference(value, exportColumns);
    const toRemove = difference(exportColumns, value);
    const auxColumns = cloneDeep(orderedColumns);
    // Remove the columns than need to be removed
    for (let i = 0; i < toRemove.length; i++) {
      const indexToDelete = auxColumns.findIndex(
        (item) => item.column.key === toRemove[i].key,
      );
      auxColumns.splice(indexToDelete, 1);
    }
    // Reorder the ids of the ordered columns
    const reorderedColumns: ColumnSortableItem[] = auxColumns.map(
      (column, i) => {
        return { column: column.column, id: i + 1 };
      },
    );
    for (let j = 0; j < toAdd.length; j++) {
      const column = columns.find((c) => c.key === toAdd[j].key);
      if (column) {
        reorderedColumns.push({
          column,
          id: reorderedColumns.length + j + 1,
        });
      }
    }
    setOrderedColumns(reorderedColumns);
  };

  const handleRemoveColumn = (id: number) => (): void => {
    const auxColumns = cloneDeep(orderedColumns);
    const indexToDelete = auxColumns.findIndex((item) => item.id === id);
    auxColumns.splice(indexToDelete, 1);
    setOrderedColumns(auxColumns);
  };

  const reorderColumns = (reorderedItems: ColumnSortableItem[]): void => {
    setOrderedColumns(reorderedItems);
  };

  return (
    <RightDrawer
      open={openDrawer}
      actionLabel="Export"
      title={`Export ${isActive ? 'Devices' : 'Discovered Devices'}`}
      disableAction={!defaultColumns && !orderedColumns.length}
      actionLoading={loading}
      actionCallback={handleSubmitCallback}
      onCloseDrawer={handleCloseDrawer}
      content={
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div
              className={clsx('br-1 p-4 mb-2', classes.importTemplateContainer)}
            >
              <InfoIcon className={clsx('mr-4', classes.infoIcon)} />
              <div>
                <Typography component="div" variant="button" color={'#fff'}>
                  All selected devices based on the filters you have chosen will
                  be exported. To change the selection, please go back and
                  adjust the filters.
                </Typography>
                <Typography component="div" variant="button" color={'#fff'}>
                  You can choose what data columns to export. The chosen columns
                  will persist for next exports.
                </Typography>
                <Typography component="div" variant="button" color={'#fff'}>
                  Recomendation: choose the "Default export columns" option to
                  export the same data needed for the import process.
                </Typography>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} className="mb-2">
            <SwitchButton
              label="Default export columns, same columns as the required ones to import"
              prop="default_columns"
              value={defaultColumns}
              onSwitchChange={handleChooseDefaultColumns}
            />
            <SwitchButton
              label="Customize export columns"
              prop="custom_columns"
              value={!defaultColumns}
              onSwitchChange={handleChooseDefaultColumns}
            />
          </Grid>
          <Grid item xs={12}>
            {defaultColumns && (
              <List dense>
                {columns
                  .filter((c) => c.is_default)
                  .map((column) => (
                    <ListItem key={column.key}>
                      <ListItemText primary={column.name} />
                    </ListItem>
                  ))}
              </List>
            )}
            {!defaultColumns && (
              <>
                <Typography variant="body2" className="custom-label">
                  Select the columns to export
                </Typography>
                <Autocomplete
                  selectOnFocus
                  handleHomeEndKeys
                  multiple
                  value={orderedColumns.map((c) => c.column)}
                  isOptionEqualToValue={(
                    option: DeviceExportColumnn,
                    value: DeviceExportColumnn,
                  ) => option.key === value.key}
                  onChange={handleChangeChosenColumns}
                  noOptionsText={
                    <MenuItem key="_no-options">
                      <Typography variant="button" component="div">
                        No column found
                      </Typography>
                    </MenuItem>
                  }
                  renderInput={(params): React.ReactNode => (
                    <TextField
                      {...params}
                      placeholder="Search available columns"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                  options={columns.sort((a, b) => {
                    if (a.name < b.name) {
                      return 1;
                    }
                    if (a.name < b.name) {
                      return -1;
                    }
                    return 0;
                  })}
                  getOptionLabel={(option) => option.name}
                  renderOption={(
                    props: React.HTMLAttributes<HTMLLIElement>,
                    column: DeviceExportColumnn,
                  ): React.ReactElement => (
                    <MenuItem
                      dense
                      {...props}
                      className="m-4 p-2"
                      key={column.key}
                      value={column.key}
                    >
                      <Checkbox
                        checked={orderedColumns
                          .map((c) => c.column.key)
                          .includes(column.key)}
                      />
                      {column.name}
                    </MenuItem>
                  )}
                />
                <SortableList
                  items={orderedColumns}
                  onChange={reorderColumns}
                  renderItem={(item) => (
                    <SortableList.Item id={item.id}>
                      <SortableList.DragHandle />
                      <Grid
                        container
                        spacing={2}
                        direction="row"
                        className="ml-2"
                        alignItems="center"
                      >
                        <Grid item xs={10} className="pl-0">
                          <Typography component="div" variant="button">
                            {item.column.name}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          className="mt-6"
                        >
                          <IconButton onClick={handleRemoveColumn(item.id)}>
                            <DeleteIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </SortableList.Item>
                  )}
                />
              </>
            )}
          </Grid>
        </Grid>
      }
    />
  );
};

export default ExportDevices;
