import {
  PaginationFilter,
  DeviceConfigs,
  DeviceTypes,
} from '@edgeiq/edgeiq-api-js';
import { Button, CircularProgress, Grid, Typography } from '@mui/material';
import clsx from 'clsx';
import { useEffect, useState, ChangeEvent } from 'react';

import {
  defaultItemsPerPage,
  errorHighlight,
  genericViewOptions,
  optionsPaginationsFilter,
  deleteHighlight,
} from '../../app/constants';
import Card from '../../components/Card';
import CardsGrid from '../../components/CardsGrid';
import Header from '../../containers/HeaderWithActionButton';
import ListSelection from '../../components/ListSelection';
import SharedTable, { TableItemType } from '../../components/SharedTable';
import getInitialSorting from '../../helpers/getInitialSorting';
import parseFilters from '../../helpers/parseFilters';
import { SortingOption } from '../../models/common';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setAlert } from '../../redux/reducers/alert.reducer';
import { setStateConfigs } from '../../redux/reducers/configs.reducer';
import {
  setStateDeviceTypes,
  setOptionsDeviceTypes,
} from '../../redux/reducers/deviceTypes.reducer';
import {
  setSorting,
  setViewOption,
} from '../../redux/reducers/filters.reducer';
import { RootState } from '../../redux/store';
import { SORTING_OPTIONS_GENERIC_NAME } from '../../constants/sortings';
import ActionDialog from '../../components/ActionDialog';
import DeviceConfigConnectionFilter from './DeviceConfigConnectionFilter';

import { ConfigsColumns } from './columns';
import ConfigsCard from './ConfigsCard';

const Configs: React.FC = () => {
  const { configs } = useAppSelector((state: RootState) => state.configs);
  const filters = useAppSelector((state: RootState) => state.filters);
  const { userCompanies } = useAppSelector((state: RootState) => state.user);
  const { deviceTypes } = useAppSelector(
    (state: RootState) => state.deviceTypes,
  );
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [selectedView, setSelectedView] = useState(filters.configs.view);
  const [selectedConfigs, setSelectedConfigs] = useState<string[]>([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [ActionDialogOpen, setActionDialogOpen] = useState(false);
  const [selectedSorting, setSelectedSorting] = useState<SortingOption>(
    getInitialSorting(filters.configs.sortBy, SORTING_OPTIONS_GENERIC_NAME),
  );

  useEffect(() => {
    if (deviceTypes.length === 0) {
      DeviceTypes.list({}, optionsPaginationsFilter)
        .then((result) => {
          dispatch(setStateDeviceTypes(result.deviceTypes));
          dispatch(setOptionsDeviceTypes(result.deviceTypes));
        })
        .catch((error) => {
          dispatchError(error.message);
        });
    }
  }, []);

  useEffect(() => {
    whenComponetMounted(1);
  }, [filters.configs]);

  const whenComponetMounted = (newPage: number): void => {
    setLoading(true);
    setSelectedConfigs([]);
    getDeviceConfigs(newPage);
  };

  const getDeviceConfigs = (pageNumber: number, addPage = false): void => {
    const pagination: PaginationFilter = {
      itemsPerPage: defaultItemsPerPage,
      order_by: selectedSorting.value,
      page: pageNumber,
    };

    DeviceConfigs.list(
      parseFilters(filters.configs.filters ?? {}, filters.case_sensitive),
      pagination,
    )
      .then((result) => {
        const newConfigs = addPage
          ? [...configs, ...result.deviceConfigs]
          : result.deviceConfigs;
        dispatch(setStateConfigs(newConfigs));
        setTotalAndPage(result.pagination.total, addPage);
      })
      .catch((error) => {
        dispatchError(error.message);
      })
      .finally(() => noLoading());
  };

  const setTotalAndPage = (newTotal: number, addPage = false): void => {
    setTotal(newTotal);
    if (addPage) {
      setPage(page + 1);
    }
  };

  const dispatchError = (errorMessage: string): void => {
    dispatch(
      setAlert({
        highlight: errorHighlight,
        message: errorMessage,
        type: 'error',
      }),
    );
  };

  const noLoading = (): void => {
    setLoading(false);
    setLoadingMore(false);
  };

  const handleSorting = (option: SortingOption): void => {
    dispatch(setSorting(option.value, 'configs'));
    setSelectedSorting(option);
  };

  const handleSelectAll = (): void => {
    if (selectedConfigs.length !== configs.length) {
      setSelectedConfigs(configs.map((configType) => configType._id));
    } else {
      setSelectedConfigs([]);
    }
  };

  const handleSelectView = (view: string): void => {
    dispatch(setViewOption(view, 'configs'));
    setSelectedView(view);
  };

  const handleTableSorting = (value: string): void => {
    console.info(value);
  };

  const checkConfigsCallback =
    (configId: string) =>
    (event: ChangeEvent<HTMLInputElement>): void => {
      if (event.target.checked) {
        setSelectedConfigs([...selectedConfigs, configId]);
      } else {
        setSelectedConfigs(selectedConfigs.filter((item) => item !== configId));
      }
    };

  const handleLoadMore = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    setLoadingMore(true);
    getDeviceConfigs(page + 1, true);
  };

  const openDeleteModal = (): void => {
    setActionDialogOpen(true);
  };

  const closeDeleteModal = (): void => {
    setActionDialogOpen(false);
  };

  const handleBulkDelete = (): void => {
    setLoadingDelete(true);
    if (selectedConfigs.length === 1) {
      DeviceConfigs.delete(selectedConfigs[0])
        .then((_result) => {
          dispatch(
            setAlert({
              highlight: deleteHighlight(
                selectedConfigs.length,
                'Config',
                'Configs',
              ),
              type: 'success',
            }),
          );
          whenComponetMounted(page);
        })
        .catch((error) => {
          dispatchError(error.message);
        })
        .finally(() => {
          setLoadingDelete(false);
          closeDeleteModal();
        });
    } else {
      DeviceConfigs.deleteMultiple(selectedConfigs)
        .then((_result) => {
          dispatch(
            setAlert({
              link: 'messages#bulk-jobs',
              linkText: 'bulk job',
              message:
                'A <link> has been created to delete the selected configs',
              type: 'success',
            }),
          );
          whenComponetMounted(1);
        })
        .catch((error) => {
          dispatchError(error.message);
        })
        .finally(() => {
          setLoadingDelete(false);
          closeDeleteModal();
        });
    }
  };

  const generateCards = (): JSX.Element[] => {
    return configs.map((config) => (
      <Card
        checked={selectedConfigs.includes(config._id)}
        checkboxCallback={checkConfigsCallback}
        id={config._id}
        baseLink="/config"
        content={<ConfigsCard config={config} userCompanies={userCompanies} />}
      />
    ));
  };

  return (
    <Grid container direction="column" spacing={0}>
      <Header
        title="Network Configurations"
        link="new-config-connection"
        actionLabel="Create New Network Config"
        model="device_config"
      />
      <DeviceConfigConnectionFilter total={total} deviceTypes={deviceTypes} />
      <ListSelection
        selectedSorting={selectedSorting}
        selectedView={selectedView}
        sortingOptions={SORTING_OPTIONS_GENERIC_NAME}
        viewsOptions={genericViewOptions}
        itemsSelected={selectedConfigs.length !== 0}
        allSelected={
          configs.length !== 0 && selectedConfigs.length === configs.length
        }
        deleteAction={true}
        sortingCallback={handleSorting}
        selectAllCallback={handleSelectAll}
        selectViewCallback={handleSelectView}
        deleteCallback={openDeleteModal}
      />
      {loading ? (
        <Grid container className="loading-container">
          <CircularProgress size={75} thickness={5} />
        </Grid>
      ) : (
        <>
          {selectedView === 'grid' && <CardsGrid cards={generateCards()} />}
          {selectedView === 'list' && (
            <SharedTable
              columns={ConfigsColumns(deviceTypes, userCompanies)}
              rows={configs as TableItemType[]}
              sortBy={selectedSorting.value}
              sortDirection={
                selectedSorting.value.indexOf('-') === -1 ? 'asc' : 'desc'
              }
              allSelected={selectedConfigs.length === configs.length}
              loading={loading}
              selectedItemsIds={selectedConfigs}
              onRequestSort={handleTableSorting}
              selectAllCallback={handleSelectAll}
              checkboxCallback={checkConfigsCallback}
            />
          )}
          {configs.length !== total && (
            <Grid item xs={12} className={clsx('mb-9 loading-container')}>
              <Button variant="outlined" size="large" onClick={handleLoadMore}>
                {!loadingMore ? (
                  <Typography variant="button">Load more</Typography>
                ) : (
                  <CircularProgress size={25} />
                )}
              </Button>
            </Grid>
          )}
        </>
      )}
      <ActionDialog
        open={ActionDialogOpen}
        loading={loadingDelete}
        content={
          <>
            <span>{`You are about to delete this ${
              selectedConfigs.length === 1 ? 'config' : 'configs'
            }:`}</span>
            <ul>
              {configs
                .filter((config) => selectedConfigs.includes(config._id))
                .map((config) => (
                  <li key={config._id}>
                    {config.name} - Id: {config._id}
                  </li>
                ))}
            </ul>
          </>
        }
        onCloseCallback={closeDeleteModal}
        onDeleteCallback={handleBulkDelete}
      />
    </Grid>
  );
};

export default Configs;
