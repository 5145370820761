import React, { ChangeEvent, useEffect, useState } from 'react';
import { Button, CircularProgress, Grid, Typography } from '@mui/material';
import {
  EscrowDevice,
  EscrowDevices,
  PaginationFilter,
} from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import {
  setStateEscrowDevices,
  setSelectedEscrowDevice,
  getEscrowDeviceSelector,
} from '../../../redux/reducers/devices.reducer';
import {
  setSorting,
  setViewOption,
} from '../../../redux/reducers/filters.reducer';
import { setAlert } from '../../../redux/reducers/alert.reducer';
import Header from '../../../containers/HeaderWithActionButton';
import ListSelection from '../../../components/ListSelection';
import Card from '../../../components/Card';
import CardsGrid from '../../../components/CardsGrid';
import SharedTable from '../../../components/SharedTable';
import ActionDialog from '../../../components/ActionDialog';
import {
  defaultItemsPerPage,
  deleteHighlight,
  errorHighlight,
  genericViewOptions,
  optionsPaginationsFilter,
} from '../../../app/constants';
import { SortingOption } from '../../../models/common';
import getInitialSorting from '../../../helpers/getInitialSorting';
import parseFilters from '../../../helpers/parseFilters';
import DevicesGenre from '../DevicesGenre';
import DevicesExtraActions from '../DevicesExtraActions';
import useStyles from '../styles';
import EscrowDeviceDrawer from './EscrowDeviceDrawer';
import EscrowDeviceCard from './EscrowDeviceCard';
import EscrowDevicesFilters from './EscrowDevicesFilters';
import { EscrowColumns } from './columns';
import { SORTING_OPTIONS_DEVICES } from '../../../constants/sortings';

const EscrowDevicesPage: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const filters = useAppSelector((state: RootState) => state.filters);
  const devicesState = useAppSelector((state: RootState) => state.devices);
  const [escrowDevices, setEscrowDevices] = useState<EscrowDevice[]>(
    devicesState.escrowDevices,
  );
  const [escrowSorting, setEscrowSorting] = useState<SortingOption>(
    getInitialSorting(filters.escrowDevices.sortBy, SORTING_OPTIONS_DEVICES),
  );
  const [selectedDevices, setSelectedDevices] = useState<string[]>([]);
  const [selectedView, setSelectedView] = useState(filters.escrowDevices.view);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingSelectAll, setLoadingSelectAll] = useState(false);
  const [ActionDialogOpen, setActionDialogOpen] = useState(false);
  const [editEscrowOpen, setEditEscrowOpen] = useState(false);

  const dispatchError = (errorMessage: string, highlight?: string): void => {
    dispatch(
      setAlert({
        highlight: highlight ?? errorHighlight,
        message: errorMessage,
        type: 'error',
      }),
    );
  };

  const setTotalAndPage = (newTotal: number, addPage = false): void => {
    setTotal(newTotal);
    if (addPage) {
      setPage(page + 1);
    }
  };

  const noLoading = (): void => {
    setLoading(false);
    setLoadingMore(false);
  };

  const getDevices = (pageNumber: number, addPage = false): void => {
    const pagination: PaginationFilter = {
      itemsPerPage: defaultItemsPerPage,
      order_by: escrowSorting.value,
      page: pageNumber,
    };

    EscrowDevices.list(
      parseFilters(filters.escrowDevices.filters ?? {}, filters.case_sensitive),
      pagination,
    )
      .then((result) => {
        const newEscrowDevices = addPage
          ? [...escrowDevices, ...result.escrowDevices]
          : result.escrowDevices;
        setEscrowDevices(newEscrowDevices);
        dispatch(setStateEscrowDevices(newEscrowDevices));
        setTotalAndPage(result.pagination.total, addPage);
      })
      .catch((error) => {
        dispatchError(error.message);
      })
      .finally(() => noLoading());
  };

  const whenComponetMounted = (newPage: number): void => {
    setLoading(true);
    setSelectedDevices([]);
    getDevices(newPage);
  };

  useEffect(() => {
    whenComponetMounted(1);
  }, [filters.escrowDevices, devicesState.devicesGenre]);

  useEffect(() => {
    setEscrowDevices(devicesState.escrowDevices);
  }, [devicesState.escrowDevices]);

  const handleLoadMore = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    setLoadingMore(true);
    getDevices(page + 1, true);
  };

  const checkDeviceCallback =
    (deviceId: string) =>
    (_event: ChangeEvent<HTMLInputElement>, checked: boolean): void => {
      if (checked) {
        setSelectedDevices([...selectedDevices, deviceId]);
      } else {
        setSelectedDevices(selectedDevices.filter((item) => item !== deviceId));
      }
    };

  const handleSorting = (option: SortingOption): void => {
    dispatch(setSorting(option.value, 'escrowDevices'));
    setEscrowSorting(option);
  };

  const handleSelectView = (view: string): void => {
    dispatch(setViewOption(view, 'escrowDevices'));
    setSelectedView(view);
  };

  const handleTableSorting = (value: string): void => {
    console.info(value);
  };

  const handleSelectAll = (): void => {
    if (selectedDevices.length !== total) {
      if (escrowDevices.length === total) {
        // All devices are already in discoveredDevices array
        setSelectedDevices(escrowDevices.map((device) => device._id));
      } else {
        setLoadingSelectAll(true);
        // Get the total of discoveredDevices by the filters, we already have the total number
        EscrowDevices.list(
          parseFilters(
            filters.escrowDevices.filters ?? {},
            filters.case_sensitive,
          ),
          optionsPaginationsFilter,
        )
          .then((result) => {
            setSelectedDevices(
              result.escrowDevices.map((device) => device._id),
            );
          })
          .catch((error) => {
            dispatchError(error.message);
          })
          .finally(() => {
            setLoadingSelectAll(false);
          });
      }
    } else {
      setSelectedDevices([]);
    }
  };

  const openDeleteModal = (): void => {
    setActionDialogOpen(true);
  };

  const closeDeleteModal = (): void => {
    setActionDialogOpen(false);
  };

  const handleBulkDelete = (): void => {
    setLoadingDelete(true);
    if (selectedDevices.length === 1) {
      EscrowDevices.delete(selectedDevices[0])
        .then((_result) => {
          dispatch(
            setAlert({
              highlight: deleteHighlight(1, 'Escrow Device', 'Escrow Devices'),
              type: 'success',
            }),
          );
          whenComponetMounted(page);
        })
        .catch((error) => {
          dispatchError(error.message);
        })
        .finally(() => {
          setLoadingDelete(false);
          closeDeleteModal();
        });
    } else {
      EscrowDevices.deleteMultiple(selectedDevices)
        .then((_result) => {
          dispatch(
            setAlert({
              link: 'messages#bulk-jobs',
              linkText: 'bulk job',
              message:
                'A <link> has been created to delete the selected escrow devices',
              type: 'success',
            }),
          );
          whenComponetMounted(1);
        })
        .catch((error) => {
          dispatchError(error.message);
        })
        .finally(() => {
          setLoadingDelete(false);
          closeDeleteModal();
        });
    }
  };

  const handleEscrowDeviceClick = (id: string): void => {
    dispatch(
      setSelectedEscrowDevice(
        getEscrowDeviceSelector(devicesState, id) as EscrowDevice,
      ),
    );
    setEditEscrowOpen(true);
  };

  const closeEscrowDrawer = (): void => {
    setEditEscrowOpen(false);
  };

  const allSelected =
    escrowDevices.length !== 0 &&
    selectedDevices.length === escrowDevices.length;

  return (
    <Grid container direction="column" spacing={0}>
      <Header
        title="Devices"
        link="new-device"
        actionLabel="Add New Device"
        model={'escrow_device'}
        nextTitleContent={<DevicesGenre />}
      />
      <EscrowDevicesFilters total={total} />
      <ListSelection
        deleteAction={true}
        selectedLabel="Escrow Device"
        selectedSorting={escrowSorting}
        selectedView={selectedView}
        sortingOptions={SORTING_OPTIONS_DEVICES}
        viewsOptions={genericViewOptions}
        itemsSelected={selectedDevices.length !== 0}
        allSelected={allSelected}
        itemsSelectedCount={selectedDevices.length}
        loadingAllItems={loadingSelectAll}
        selectedActions={
          <DevicesExtraActions selectedDevicesIds={selectedDevices} />
        }
        sortingCallback={handleSorting}
        selectAllCallback={handleSelectAll}
        selectViewCallback={handleSelectView}
        deleteCallback={openDeleteModal}
      />
      {loading ? (
        <Grid container className="loading-container">
          <CircularProgress size={75} thickness={5} />
        </Grid>
      ) : (
        <>
          {selectedView === 'grid' && (
            <CardsGrid
              cards={escrowDevices.map((escrowDevice) => (
                <Card
                  checked={selectedDevices.includes(escrowDevice._id)}
                  checkboxCallback={checkDeviceCallback}
                  id={escrowDevice._id}
                  onClickCallback={handleEscrowDeviceClick}
                  content={<EscrowDeviceCard escrowDevice={escrowDevice} />}
                />
              ))}
            />
          )}
          {selectedView === 'list' && (
            <SharedTable
              columns={EscrowColumns}
              rows={escrowDevices}
              sortBy={escrowSorting.value}
              sortDirection={
                escrowSorting.value.indexOf('-') === -1 ? 'asc' : 'desc'
              }
              allSelected={allSelected}
              loading={loading}
              selectedItemsIds={selectedDevices}
              hasActionColumn={true}
              onRequestSort={handleTableSorting}
              selectAllCallback={handleSelectAll}
              checkboxCallback={checkDeviceCallback}
            />
          )}
          {escrowDevices.length !== total && (
            <Grid
              item
              xs={12}
              className={clsx('mb-9', classes.loadMoreContainer)}
            >
              <Button variant="outlined" size="large" onClick={handleLoadMore}>
                {!loadingMore ? (
                  <Typography variant="button">Load more</Typography>
                ) : (
                  <CircularProgress size={25} />
                )}
              </Button>
            </Grid>
          )}
        </>
      )}
      <ActionDialog
        open={ActionDialogOpen}
        loading={loadingDelete}
        content={
          <>
            <span>{`You are about to delete this ${
              selectedDevices.length === 1 ? 'device' : 'devices'
            }:`}</span>
            <ul>
              {escrowDevices
                .filter((device) => selectedDevices.includes(device._id))
                .map((device) => (
                  <li key={device._id}>{device.unique_id}</li>
                ))}
            </ul>
          </>
        }
        onCloseCallback={closeDeleteModal}
        onDeleteCallback={handleBulkDelete}
      />
      <EscrowDeviceDrawer
        open={editEscrowOpen}
        handleCloseDrawer={closeEscrowDrawer}
      />
    </Grid>
  );
};

export default EscrowDevicesPage;
