import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import {
  Grid,
  Typography,
  FormControl,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Button,
  CircularProgress,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Authentication,
  Companies,
  EIQLoginUser,
  UserTypes,
} from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import {
  setUserCompanies,
  setUserCompany,
  setUserData,
  setUserType,
} from '../../redux/reducers/user.reducer';
import { useAppDispatch } from '../../redux/hooks';
import {
  MAX_ACCOUNTS,
  errorsMessages,
  loginSessionTokenExpiration,
} from '../../app/constants';
import { Alert } from '../../components/ToastAlert/ToastAlert';
import useStyles from './styles';

const Login: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [error, setError] = useState<string>();
  const [submitting, setSubmitting] = useState(false);
  const [values, setValues] = useState({
    email: '',
    password: '',
    showPassword: false,
  });

  const handleChange =
    (prop: string) =>
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      setValues({ ...values, [prop]: event.target.value });
    };

  const handleClickShowPassword = (): void => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ): void => {
    event.preventDefault();
  };

  const onSubmit = (event: React.SyntheticEvent): void => {
    event.preventDefault();
    setSubmitting(true);
    let loggedUser: EIQLoginUser;
    Authentication.login(
      values.email,
      values.password,
      loginSessionTokenExpiration,
    )
      .then((user: EIQLoginUser) => {
        loggedUser = user;
        // It's crucial for the branding to have the company of the user first
        dispatch(setUserData(user));
        return Companies.getOneById(user.company_id);
      })
      .then((userCompany) => {
        dispatch(setUserCompany(userCompany));
        // Then to have the user type to know the permissions and so to show or not create buttons for example
        return UserTypes.getOneById(loggedUser.user_type_id);
      })
      .then((userType) => {
        dispatch(setUserType(userType));
        // For most users, getting the MAX_ACCOUNTS number of accounts helps with efficency with the first load or after login
        return Companies.list({}, { itemsPerPage: MAX_ACCOUNTS, page: 1 });
      })
      .then((result) => {
        dispatch(setUserCompanies(result.companies));
      })
      .catch((responseError) => {
        if (errorsMessages[responseError.message]) {
          setError(errorsMessages[responseError.message]);
        } else {
          setError(responseError.message);
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const goToForgetPass = (): void => {
    navigate('/recover-password');
  };

  return (
    <Grid container direction="column">
      <Grid item xs={12}>
        <Typography variant="subtitle1" className={clsx('mb-9', classes.title)}>
          Login
        </Typography>
      </Grid>
      {error && (
        <Grid item xs={12} className="mb-7">
          <Alert type="error" message={error} highlight="Error" />
        </Grid>
      )}
      <Grid item xs={12}>
        <form onSubmit={onSubmit} className={classes.formContainer}>
          <Typography variant="body2" className="custom-label">
            Email
          </Typography>
          <FormControl variant="outlined" className="mb-7">
            <OutlinedInput
              name="email"
              placeholder="Email"
              type="text"
              required={true}
              value={values.email}
              onChange={handleChange('email')}
            />
          </FormControl>
          <Typography variant="body2" className="custom-label">
            Password
          </Typography>
          <FormControl variant="outlined" className="mb-7">
            <OutlinedInput
              name="password"
              placeholder="Password"
              type={values.showPassword ? 'text' : 'password'}
              required={true}
              value={values.password}
              onChange={handleChange('password')}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <Button
            color="primary"
            variant="text"
            type="button"
            onClick={goToForgetPass}
            className={clsx('mb-7 p-0', classes.linkButton)}
          >
            <Typography variant="button">Forgot password?</Typography>
          </Button>
          <Button
            className={classes.submitButton}
            color="primary"
            size="large"
            variant="contained"
            type="submit"
            disabled={submitting}
          >
            {!submitting ? 'Log In' : <CircularProgress size={25} />}
          </Button>
        </form>
      </Grid>
    </Grid>
  );
};

export default Login;
